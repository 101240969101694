import FourZeroFourImage from "@images/404.webp";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import { FC } from "react";
import {
  i18translateType,
  PagePropsBasicType,
} from "../../../@types/types-body";

const FourZeroFourSection: FC<PagePropsBasicType> = ({ namespace }) => {
  const { t }: i18translateType = useTranslation(namespace);
  return (
    <section>
      <div className="relative mt-24">
        <h1 className="text-xl font-bold md:text-5xl text-center text-crowlyn-dark">
          {t("404.title")}
        </h1>
        <h1 className="text-xs md:text-xl mb-5 text-center">{t("404.span")}</h1>
        <h1 className="text-sm text-justify md:mb-24 md:text-2xl md:text-center text-crowlyn-night">
          {t("404.description")}
        </h1>
        <div className="w-size h-full relative">
          <Image src={FourZeroFourImage} fill={false} alt={"404"} />
        </div>
      </div>
    </section>
  );
};

export default FourZeroFourSection;
